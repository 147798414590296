'use client';
import classNames from 'classnames';

import gtm from '@/utils/gtm';
import { saveCookieForShopify } from '@elem/ui-common/utils/CookieForShopify';
import BhStat from 'lowcode/BhStat';
import { useRouter } from 'next/router';
import './index.scss';

interface dataLayerType {
    event?: string;
    event_name?: 'buy' | string;
    product_name?: string;
    position?: string;
    [key: string]: any;
}

export interface EfLinkProps {
    /**
     * 跳转链接
     */
    href?: string;
    /**
     * 跳转方式
     */
    target?: string;
    /**
     * 样式名
     */
    className?: string;
    /**
     * 是否阻止冒泡
     */
    stopPropagation?: boolean;
    /**
     * 是否阻止浏览器默认行为
     */
    preventDefault?: boolean;
    /**
     * 埋点
     */
    dataLayer?: dataLayerType | dataLayerType[] | null;
    /**
     * 点击事件
     */
    onClick?: () => void;
    /**
     * 子内容
     */
    children?: React.ReactNode;
}
const uaEventName = 'uaEvent';
export default function EfLink({
    href,
    target = '',
    className,
    stopPropagation = false,
    preventDefault = false,
    dataLayer = null,
    onClick,
    children,
    ...rest
}: EfLinkProps) {
    const url = href?.substring(0, 43) || '';
    const isNoneHttp = !url.includes('http');
    const { locale = 'us' } = useRouter();
    const isIncudesWebsite = url.includes('https://www.ecoflow.com');
    const isWebsite = isNoneHttp || isIncudesWebsite;
    const prefixCls = `ef-link`;
    const classes = classNames(prefixCls, {
        [`${className}`]: className,
    });
    // 触发埋点
    const handleGTM = (params: any) => {
        if (Array.isArray(params)) {
            params.forEach((v) => gtm.push(v));
        } else if (params) {
            gtm.push({
                event: uaEventName,
                ...params,
            });
            try {
                const eventObj = {
                    ...params,
                };
                BhStat.push(eventObj);
            } catch (error: any) {}
        }
    };
    // 点击事件
    const clickHandle = (e: any) => {
        if (stopPropagation) {
            e.stopPropagation();
        }
        if (preventDefault) {
            e.preventDefault();
        }
        dataLayer && href && handleGTM(dataLayer); // 必须是 href 有值,且 dataLayer 有值
        saveCookieForShopify(href, locale);
        onClick && onClick(e); //触发回调
    };

    return (
        <a
            className={classes}
            href={href}
            target={['_blank', '_self', '_parent', '_top']?.includes(target) ? target : isWebsite ? '_self' : '_blank'}
            rel="noreferrer"
            onClick={clickHandle}
            {...rest}
        >
            {children}
        </a>
    );
}
